import React from 'react'
import './Footer.css';
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>

{/* solution section start  */}
<section className="solution_footer_div">
    <div className="container">
        <div className="row">
            {/* col start */}
            <div className="col-lg-12 col-md-12 col-12">
                <div className="solution_ftr_txt_div">
                    <h4>If You Need Any Solution.....We Are Available For You</h4>
                    <Link to="/Contact"> <i class='bx bxs-contact'></i> Contact Us Now !</Link>
                </div>
            </div>
            {/* col end */}
        </div>
    </div>
</section>
{/* solution section end */}

     {/* footer start */}
      <footer className='footer'>
       <div className="container">
        <div className="row">
            {/* col start */}
            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="footer_main_div">
                    <div className="footer_title_di">
                        <h5>My Account</h5>
                    </div>
                    <ul>
                        <li><Link to="My-Profile"><i class='bx bx-chevrons-right'></i> My Profile</Link></li>
                        <li><Link to="Cart"><i class='bx bx-chevrons-right'></i> Cart</Link></li>
                        <li><Link to="Orders"><i class='bx bx-chevrons-right'></i> Orders</Link></li>
                        <li><Link to="Login"><i class='bx bx-chevrons-right'></i> Login</Link></li>
                    </ul>
                </div>
            </div>
            {/* col end */}

             {/* col start */}
             <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="footer_main_div">
                    <div className="footer_title_di">
                        <h5>Information</h5>
                    </div>
                    <ul>
                        <li><Link to="/"><i class='bx bx-chevrons-right'></i> Home</Link></li>
                        <li><Link to="About"><i class='bx bx-chevrons-right'></i> Company Profile</Link></li>
                        <li><Link to=""><i class='bx bx-chevrons-right'></i> Blog</Link></li>
                        <li><Link to="Contact"><i class='bx bx-chevrons-right'></i> Contact Us</Link></li>
                    </ul>
                </div>
            </div>
            {/* col end */}

            
             {/* col start */}
             <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="footer_main_div">
                    <div className="footer_title_di">
                        <h5>Policies</h5>
                    </div>
                    <ul>
                        <li><Link to="Terms-Conditions"><i class='bx bx-chevrons-right'></i> Terms & Conditions</Link></li>
                        <li><Link to="Privacy-Policy"><i class='bx bx-chevrons-right'></i> Privacy Policy</Link></li>
                        <li><Link to="Refund-Cancellation"><i class='bx bx-chevrons-right'></i> Refund & Cancellation</Link></li>
                        <li><Link to=""><i class='bx bx-chevrons-right'></i> Free Delivery</Link></li>
                    </ul>
                </div>
            </div>
            {/* col end */}


              {/* col start */}
              <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="footer_main_div">
                    <div className="footer_title_di">
                        <h5>Contact Us</h5>
                    </div>
                    {/*  */}
                  <div className="d-flex">
  <div className="flex-shrink-0">
  <i class='bx bx-map'></i>
  </div>
  <div className="flex-grow-1 ms-3">
   <p>Naharpur Car Market, Near G Block, Rohini, Sector -7, Delhi - 110085 (INDIA)</p>
  </div>
</div>
 {/*  */}

{/*  */}
<div className="d-flex align-items-center">
  <div className="flex-shrink-0">
  <i class='bx bx-phone-call'></i>
  </div>
  <div className="flex-grow-1 ms-3">
  <Link to="tel:+91 9856622622"> +91 9856622622</Link>
  </div>
</div>
 {/*  */}

 {/*  */}
<div className="d-flex align-items-center pt-2">
  <div className="flex-shrink-0">
  <i class='bx bx-mail-send'></i>
  </div>
  <div className="flex-grow-1 ms-3">
  <Link to="mailto:info@priyavindustries"> info@priyavindustries</Link>
  </div>
</div>
 {/*  */}

 <div className="followus-footer">
    <b>Follow Us:</b>
    <p>
        <Link to="" className='bx bxl-facebook'></Link>
        <Link to="" className='bx bxl-instagram'></Link>
        <Link to="" className='bx bxl-youtube'></Link>
    </p>
 </div>


                </div>
            </div>
            {/* col end */}

        </div>
       </div>
      </footer>
      {/* footer end */}

      {/* copyright section start */}
      <section className="copyright_section">
        <div className="container">
            <div className="row">
                {/* col start */}
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="copyright_txt_div">
                        <p>Copyright © 2024 Priyav Industries | All Rights Reserved | Designed By <Link to="https://webflowindia.com/" target='_blank'> Web Flow India.</Link></p>
                    </div>
                </div>
                {/* col end */}
            </div>
        </div>
      </section>
      {/* copyright section end */}

       

    </>
  )
}

export default Footer
