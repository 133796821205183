
// All Categories =========================================
export const Allcategoriesdata=[
    {
     id: 1,
     image: '../products/1.png',
     category_name: "Chalk Making Machine",
     category_desc: "A chalk making machine is a device used to manufacture chalk sticks in a systematic and efficient manner.",
     catebtn_view: "View More"
    },

    {
        id: 2,
        image: '../products/2.png',
        category_name: "Chalk Making Mold",
        category_desc: "A chalk making machine is a device used to manufacture chalk sticks in a systematic and efficient manner.",
        catebtn_view: "View More"
       },


       {
        id: 3,
        image: '../products/3.png',
        category_name: "Semi Automatic Candle Machine",
        category_desc: "A chalk making machine is a device used to manufacture chalk sticks in a systematic and efficient manner.",
        catebtn_view: "View More"
       },


       {
        id: 4,
        image: '../products/4.png',
     category_name: "Candle Making Machine",
     category_desc: "A chalk making machine is a device used to manufacture chalk sticks in a systematic and efficient manner.",
     catebtn_view: "View More"
       }
]

// All Categories end=========================================



// Chalk Making Machine=========================================
export const Cateproductdata=[
    {
     id: 1,
     mobileprodataname: 'mobileproducts',
     categoryname: 'Chalk Making Machine',
     image: '../products/1.png',
     cateproduct_name: "Dust Less School Chalk Making Machine",
     cateproduct_netprice: 185000,
     cateproduct_dissprice: 148000,
     cateproduct_offdiv: "30% Off",
     cateproduct_freedlv: "Free Delivery"
    },

    {
        id: 2,
        mobileprodataname: 'mobileproducts',
        categoryname: 'Chalk Making Machine',
        image: '../products/2.png',
        cateproduct_name: "School Chalk Making Machine",
        cateproduct_netprice: 185000,
        cateproduct_dissprice: 148000,
        cateproduct_offdiv: "25% Off",
        cateproduct_freedlv: "Free Delivery"
       },


       {
        id: 3,
        mobileprodataname: 'mobileproducts',
        categoryname: 'Chalk Making Machine',
        image: '../products/3.png',
        cateproduct_name: "Semi Automatic School Chalk Making Machine",
        cateproduct_netprice: 185000,
        cateproduct_dissprice: 148000,
        cateproduct_offdiv: "20% Off",
        cateproduct_freedlv: "Free Delivery"
       },


       {
        id: 4,
        mobileprodataname: 'mobileproducts',
        categoryname: 'Chalk Making Machine',
        image: '../products/4.png',
        cateproduct_name: "Dustless School Chalk Making Machine 1000 Piece",
        cateproduct_netprice: 185000,
        cateproduct_dissprice: 148000,
        cateproduct_offdiv: "28% Off",
        cateproduct_freedlv: "Free Delivery"
       },

   // ===========================================================================================================================Chalk Making Machine end============================================================

   {
    id: 5,
    mobileprodataname: 'mobileproducts',
    categoryname: 'Chalk Making Mold',
    image: '../products/1.png',
    cateproduct_name: "Dust Less School Chalk Making Machine",
    cateproduct_netprice: 185000,
    cateproduct_dissprice: 148000,
    cateproduct_offdiv: "30% Off",
    cateproduct_freedlv: "Free Delivery"
   },

   {
       id: 6,
       mobileprodataname: 'mobileproducts',
       categoryname: 'Chalk Making Mold',
       image: '../products/1.png',
       cateproduct_name: "School Chalk Making Machine",
       cateproduct_netprice: 185000,
       cateproduct_dissprice: 148000,
       cateproduct_offdiv: "25% Off",
       cateproduct_freedlv: "Free Delivery"
      },


      {
       id: 7,
       mobileprodataname: 'mobileproducts',
       categoryname: 'Chalk Making Mold',
       image: '../products/1.png',
       cateproduct_name: "Semi Automatic School Chalk Making Machine",
       cateproduct_netprice: 185000,
       cateproduct_dissprice: 148000,
       cateproduct_offdiv: "20% Off",
       cateproduct_freedlv: "Free Delivery",
       cateproductg: "Free Delivery"

      },


      {
       id: 8,
       mobileprodataname: 'mobileproducts',
       categoryname: 'Chalk Making Mold',
       image: '../products/1.png',
       cateproduct_name: "Dustless School Chalk Making Machine 1000 Piece",
       cateproduct_netprice: 185000,
       cateproduct_dissprice: 148000,
       cateproduct_offdiv: "28% Off",
       cateproduct_freedlv: "Free Delivery"
      },

   // ===========================================================================================================================Chalk Making Mold end============================================================
]



