import React, { useEffect, useState } from 'react';
import Productslider from './Productslider';
import { Link } from "react-router-dom";
import Mobileproductsdata from './Mobileproductsdata';

const Home = () => {



  return (
    <>

     
     {/* slider section start */}
<section className="slider_section">
<div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src="../banner/1.png" className="d-block w-100" alt="..." />
    </div>
    
  </div>
 <div className="btn_left_right">
 <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
  <i class='bx bx-left-arrow'></i>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
  <i class='bx bx-right-arrow'></i>
  </button>
 </div>
</div>
</section>
     {/* slider section end */}

     {/* wrapepr section  */}
  <div className="wrapper_div">
    
      
     {/* products section start */}
     <section className="products_section">
      <div className="container">
         {/* categories heading title */}
         <div className="products_title_div">
          <h1><Link to="">plain candle making mold</Link></h1>
          
        </div>
        {/*  */}
        <div className="row align-items-center">
          {/* col start */}
          <div className="col-lg-3 col-md-3 col-sm-12 col-12">
            <div className="fiximg_div">
              <img src="../products/fixd01.webp" alt="" className='img-fluid'/>
            </div>
          </div>

          {/* col end */}
          {/* col start */}
        <div className="col-lg-9 col-md-9 col-sm-12 col-12">
               <Productslider/>
        </div>
          {/* col start end */}
        </div>
      </div>
     </section>
     {/* products section end */}

     
     {/* products section start */}
     <section className="products_section">
      <div className="container">
         {/* categories heading title */}
         <div className="products_title_div">
          <h1><Link to="">stand candle making mold</Link></h1>
        </div>
        {/*  */}
        <div className="row align-items-center">
          {/* col start */}
          <div className="col-lg-3 col-md-3 col-sm-12 col-12">
            <div className="fiximg_div">
              <img src="../products/fixd02.jpg" alt="" className='img-fluid'/>
            </div>
          </div>

          {/* col end */}
          {/* col start */}
        <div className="col-lg-9 col-md-9 col-sm-12 col-12">
               <Productslider/>
        </div>
          {/* col start end */}
        </div>
      </div>
     </section>
     {/* products section end */}

     
     {/* products section start */}
     <section className="products_section">
      <div className="container">
         {/* categories heading title */}
         <div className="products_title_div">
          <h1><Link to="">spiral candle making mold</Link></h1>
        </div>
        {/*  */}
        <div className="row align-items-center">
          {/* col start */}
          <div className="col-lg-3 col-md-3 col-sm-12 col-12">
            <div className="fiximg_div">
              <img src="../products/fixd03.webp" alt="" className='img-fluid'/>
            </div>
          </div>

          {/* col end */}
          {/* col start */}
        <div className="col-lg-9 col-md-9 col-sm-12 col-12">
               <Productslider/>
        </div>
          {/* col start end */}
        </div>
      </div>
     </section>
     {/* products section end */}

     
     {/* products section start */}
     <section className="products_section">
      <div className="container">
         {/* categories heading title */}
         <div className="products_title_div">
          <h1><Link to="">fancy candle making mold</Link></h1>
        </div>
        {/*  */}
        <div className="row align-items-center">
          {/* col start */}
          <div className="col-lg-3 col-md-3 col-sm-12 col-12">
            <div className="fiximg_div">
              <img src="../products/fixd04.jpg" alt="" className='img-fluid'/>
            </div>
          </div>

          {/* col end */}
          {/* col start */}
        <div className="col-lg-9 col-md-9 col-sm-12 col-12">
               <Productslider/>
        </div>
          {/* col start end */}
        </div>
      </div>
     </section>
     {/* products section end */}

     
     {/* products section start */}
     <section className="products_section">
      <div className="container">
         {/* categories heading title */}
         <div className="products_title_div">
          <h1><Link to="">birthday candle making mold</Link></h1>
        </div>
        {/*  */}
        <div className="row align-items-center">
          {/* col start */}
          <div className="col-lg-3 col-md-3 col-sm-12 col-12">
            <div className="fiximg_div">
              <img src="../products/fixd05.jpg" alt="" className='img-fluid'/>
            </div>
          </div>

          {/* col end */}
          {/* col start */}
        <div className="col-lg-9 col-md-9 col-sm-12 col-12">
               <Productslider/>
        </div>
          {/* col start end */}
        </div>
      </div>
     </section>
     {/* products section end */}

     </div>
     {/* wrapepr section  end*/}

     
     {/*========================== mobile products data=========================  */}
     <Mobileproductsdata/>
     {/*========================== mobile products data=========================  */}


     {/* supports section start  */}
     <section className="support_section">
      <div className="container">
        <div className="row">
          {/* col start  */}
          <div className="col-lg-3 col-md-3 col-sm-12 col-12">
            <div className="support_txt_div">
              <div className="support_icons_div">
              <img src="../images/icon01.png" alt="priyavindustries" />

              </div>
              <h5>Free Delivery</h5>
              <p>Free delivery on all products</p>
            </div>
          </div>
          {/* col end */}

           {/* col start  */}
           <div className="col-lg-3 col-md-3 col-sm-12 col-12">
            <div className="support_txt_div support_txt_div2">
              <div className="support_icons_div support_icons_div2">
              <img src="../images/icon02.png" alt="priyavindustries" />
              </div>
              <h5>3 days Return</h5>
              <p>Return Option available</p>
            </div>
          </div>
          {/* col end */}

            {/* col start  */}
            <div className="col-lg-3 col-md-3 col-sm-12 col-12">
            <div className="support_txt_div">
              <div className="support_icons_div">
              <img src="../images/icon03.png" alt="priyavindustries" />

              </div>
              <h5>Quality Products</h5>
              <p>Selling Quality Products</p>
            </div>
          </div>
          {/* col end */}

             {/* col start  */}
             <div className="col-lg-3 col-md-3 col-sm-12 col-12">
            <div className="support_txt_div support_txt_div2">
              <div className="support_icons_div support_icons_div2">
                 <img src="../images/icon04.png" alt="priyavindustries" />
              </div>
              <h5>24/7 Support</h5>
              <p>Full customer support</p>
            </div>
          </div>
          {/* col end */}
        </div>
      </div>
     </section>
     {/* supports section end */}



    </>
  )
}

export default Home
