import React, { useEffect } from 'react';
import { Link } from "react-router-dom";


const Aboutus = () => {

  return (
    <>

    {/*  Page Title Area Start  */}

     <section className="inner_page_tisection_bred">
  <div className="container">
    <div className="row align-items-center">
      {/* col */}
      <div className="col-lg-12">
        <div className="page_ti_inner_page">
          <ul>
            <li className="active"><Link to="/">Home</Link></li> -
            <li><Link to="javascript:void(0)"> Company Profile</Link></li> 
          </ul>
        </div>
      </div>
      {/* col end */}
    </div>
  </div>
</section>

   
    {/*Page Title Area End  */}

    {/*  */}
     <section className="aboutus_section">
      <div className="container-fluid">
        {/* about us title div */}
        <div className="aboutus_head_title">
          <h2>welcome to Priyav Industries </h2>
          <p>Partner with us to avail the best-in-class Machine for Small Scale Industries, As Chalk Making Machines, All Size Candle Machine, Paper Plate Machine, Dhoop Batti Making Machine/Mold and Agarbatti Making Machine etc.</p>
        </div>
        {/* about us title div */}
        <div className="row">
{/* col start */}
<div className="col-lg-12">
  <div className="aboutus_txt_div">
    <p>Priyav Industries has been enjoying huge admiration in the market since the year 2010 for executing production related operations in an advanced manner. We, as a manufacturer and supplier, have made our production process highly advanced by using the innovative machines, tools and equipment. Our company has born to serve lasting quality of products. By sticking to our purpose of existence, we manufacture range using assured quality base materials. Our powerful alliances with some great vendors of the market assist in receiving the best materials for making final product line. Our product line that includes Semi Automatic Candle Making Machine & Manual Candle Making Mold, Spiral Candle Making Machine & Mold, Tea Light Candle Machine, Fancy& decorative Candle Mold, School Chalk Making Machine & Manual Plastic Mold, Laxman Chalk Making Machine, etc., undergoes several tests before shipment. This assists in serving right value products to buyers.</p>

    <p>Priyav Industries passionately works to attain huge profits by sticking to the concept of quality manufacturing. Partnership with certified vendors assist our company to get premium materials for manufacturing product line. Our entire range is known to be supreme in quality as it undergoes a wide line of quality based tests our business space before delivery. Quality demanding customers choose us over others in the market because of our ability to maintain superior quality standards in range. Understanding varied nature of customers demands, we maintain our product line that consists Manual Chalk Making Machine, Designer Candle Making Machine, Manual Slipper Sole Cutting Press etc., in different specifications. </p>
  </div>
</div>
{/* col end */}
        </div>
      </div>
     </section>
     {/*  */}
    </>
  )
}

export default Aboutus
