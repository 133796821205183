import React from 'react'
import { Link } from "react-router-dom";
import { Cateproductdata } from '../data/Cateproducts';

const Mobileproductsdata = () => {
  const filteredmobileItems = Cateproductdata.filter(item => item.mobileprodataname == 'mobileproducts');


  return (
    <>
       {/* Mobile_products section start */}
       <section className="mobile_products_section">
        <div className="container">
          {/*  */}
         <div className="mobile_products_title">
          <h2>Products</h2>
         </div>
          {/*  */}
          <div className="row">
            {/*  */}
            {filteredmobileItems.map((item, index) =>{
              return(
                <MobileproductsItems MobileproductsItems={item} key={index}/>
              )
            })}
              
            {/*  */}
          </div>
        </div>
      </section>
      {/* Mobile_products section end */}
    </>
  )
}

export default Mobileproductsdata;


function MobileproductsItems(props) {

    return(

 <>
  {/* col start */}
  <div className="col-lg-3 col-md-6 col-sm-6 col-6">
    <Link to="">
     <div className="mobile_products_main_div">
        <div className="mobile_products_img_div">
          <img src={ props.MobileproductsItems.image } alt="" className='img-fluid'/>
        </div>
       <div className="mobile_products_txt_div">
       <h2 className='mobile_products_name'>Dust Less School Chalk Making Machine</h2>
        <p className='mobile_products_price'><b className='netprice'>₹ 185000 /-</b> <span className='dissprice'> ₹ 148000 /-</span></p>
        <h6 className='mobile_products_disscount'> <b className='offdiv'>(30% Off)</b> <span className='freedlv'> Free Delivery</span></h6>
       </div>
      </div>
     </Link>
    </div>
    {/* col end */}

 </>

    )
}
